import React from "react"

export default ({ title, subtitle, narrow, children, ...other }) => {
    return (
        <header className="container section md:mb-lg lg:mb-xl" {...other}>
            <div className={`col ${narrow ? "md:w-1/2" : "md:w-3/4"}`}>
                <h1 className="pr-md">{title}</h1>
                {subtitle}
            </div>
            <div className={`col md:ml-auto md:mr-0 lg:text-lg ${narrow ? "md:w-1/2" : "md:w-3/4"}`}>
                {children}
            </div>
        </header>
    )
}
