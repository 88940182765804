import React from "react"
import Seo from "../components/seo"
import { injectIntl } from "react-intl"
import Intro from "../components/intro"
import Download from "../components/download"
import { useStaticQuery, graphql } from "gatsby"

const DownloadsPage = ({ children, intl }) => {
  const { download, category } = useStaticQuery(
    graphql`
      query DownloadsQuery {
        category: allSanityCategory(sort: { order: ASC, fields: order }) {
          edges {
            node {
              id
              title {
                de
                en
              }
            }
          }
        }
        download: allSanityDownload(
          sort: { order: ASC, fields: order }
        ) {
          edges {
            node {
              id
              title {
                de
                en
              }
              category {
                id
              }
              file {
                de {
                  fileName
                  file {
                    asset {
                      extension
                      size
                    }
                  }
                }
                en {
                  fileName
                  file {
                    asset {
                      extension
                      size
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <>
      <Seo
        id="downloads"
        description={intl.formatMessage({ id: "page.downloads.description" })}
      />
      {category.edges.map(({node})=> (
        <Intro title={node.title[intl.locale]} narrow={true} key={node.id}>
            {download.edges.filter((item)=> node.id === item.node.category.id).map(item => {
              const localFile = item.node.file[intl.locale];
              return(
                <Download extension={localFile.file.asset.extension}
                fileName={`/static/${localFile.fileName}.${localFile.file.asset.extension}`} 
                key={item.node.id} 
                title={item.node.title[intl.locale]} 
                size={localFile.file.asset.size}/>
            )})}       
        </Intro>
      ))}
      {children}
    </>
  )
}

export default injectIntl(DownloadsPage)
